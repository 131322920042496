import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import ProductTable2 from "./ProductTable2";
import { loadNotices,paginationItems4,loadReceive,paginationItems5} from "../../../constants/products";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";

const Items = ({ products, hoveredItem, setHoveredItem }) => {
  const [quantities, setQuantities] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleAddToCart = (productId, quantity) => {
    dispatch(addToCart({ productId, quantity }));
  };

  return (
    <ProductTable2
      products={products}
      hoveredItem={hoveredItem}
      setHoveredItem={setHoveredItem}
      quantities={quantities}
    />
  );
};

const PaginationNot = () => {
  const [products, setProducts] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [hoveredItem, setHoveredItem] = useState(null);
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [config, setConfig] = useState(null); // Added state for config

  const location = useLocation();

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true);
      try {
        const path = location.pathname;
        const loadFunction = path === "/Notices" ? loadNotices :
        path === "/Receive" ? loadReceive : null;
        await loadFunction(pageNumber, itemsPerPage);
        setProducts(path === "/Notices" ? paginationItems4 :
          path === "/Receive" ? paginationItems5 : 
          null);
      } catch (error) {
        console.error("Error loading products:", error);
      } finally {
        setLoading(false);
      }
    };
    fetchProducts();
  }, [pageNumber, itemsPerPage]);

  useEffect(() => {
    setPageNumber(1);
  }, [itemsPerPage]);


  return (
    <div className="p-5">
      {loading ? (
        <div className="text-center text-xl my-4">Cargando...</div>
      ) : (
        <Items products={products} hoveredItem={hoveredItem} setHoveredItem={setHoveredItem} />
      )}
    </div>
  );
};

export default PaginationNot;
