import React, { useState } from "react";
/*import Banner from "../../components/Banner/Banner";*/

/* productos */
import Pagination from "../../components/pageProps/shopPage/Pagination";

const Home = () => {
  const [itemsPerPage, setItemsPerPage] = useState(12);
  


  return (
    <div className="w-full mx-auto">
      {/*<Banner />*/}
      <div className="max-w-container mx-auto px-4">
        {/* ================= Products Start here =================== */}
        <div className="w-full h-full flex pb-20 gap-10">
          <div className="w-full h-full flex flex-col gap-10 mt-3">
            <Pagination itemsPerPage={itemsPerPage} />
          </div>
        </div>
        {/* ================= Products End here ===================== */}
      </div>
    </div>
  );
};

export default Home;
