import React, { createContext, useState, useEffect } from "react";
import { loadUserConfig } from "../constants/config"; // Asegúrate de importar correctamente la función loadUserConfig

// Crear el contexto
const UserConfigContext = createContext();

// Proveedor del contexto
const UserConfigProvider = ({ children }) => {
  const [config, setConfig] = useState(null);

  // Cargar la configuración al iniciar la aplicación
  useEffect(() => {
    const fetchUserConfig = async () => {
      const configData = await loadUserConfig();
      setConfig(configData);  // Guardamos la configuración en el estado
    };

    fetchUserConfig();
  }, []);  // Solo se ejecuta una vez al iniciar

  return (
    <UserConfigContext.Provider value={config}>
      {children}
    </UserConfigContext.Provider>
  );
};

// Exportar el contexto y el proveedor
export { UserConfigProvider, UserConfigContext };
