import React, { useState, useEffect, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { login } from "../../constants/config";
import ProductOferta from "../../components/pageProps/productDetails/ProductOferta";
import ProductNovedades from "../../components/pageProps/productDetails/ProductNovedades";
import { UserConfigContext } from "../../context/UserConfigContext";
import { FaEye, FaEyeSlash } from "react-icons/fa";

const SignIn = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errEmail, setErrEmail] = useState("");
  const [errPassword, setErrPassword] = useState("");
  const [successMsg, setSuccessMsg] = useState("");
  const [errorMsg, setErrorMsg] = useState("");
  const [logo, setLogo] = useState("");
  const [empresaNombre, setEmpresaNombre] = useState("");
  const [isRecovering, setIsRecovering] = useState(false); // Nuevo estado

  const config = useContext(UserConfigContext);

  useEffect(() => {
    if (config && config.empresa) {
      setLogo(config.empresa.logo);
      setEmpresaNombre(config.empresa.nombre);
    }
  }, [config]);

  const handleEmail = (e) => {
    setEmail(e.target.value);
    setErrEmail("");
  };

  const handlePassword = (e) => {
    setPassword(e.target.value);
    setErrPassword("");
  };

  const handleSignUp = async () => {
    if (!email) {
      setErrEmail("Ingresa tu correo electrónico");
      return;
    }
    if (!password) {
      setErrPassword("Ingresa tu contraseña");
      return;
    }
    try {
      const response = await login(email, password);
      if (response) {
        localStorage.setItem("user", JSON.stringify(response));
        setSuccessMsg("Inicio de sesión exitoso.");
        setErrorMsg("");
        setEmail("");
        setPassword("");
        navigate("/");
      }
    } catch (error) {
      setErrorMsg(error.message);
    }
  };

  const handlePasswordRecovery = async () => {
    if (!email) {
      setErrEmail("Ingresa tu correo electrónico para recuperar tu contraseña.");
      return;
    }
    try {
      // Aquí llamamos a un servicio para enviar el correo de recuperación
      //await sendPasswordRecoveryEmail(email); // Este es un placeholder
      setSuccessMsg(
        "Se ha enviado un enlace de recuperación a tu correo electrónico."
      );
      setErrorMsg("");
    } catch (error) {
      setErrorMsg("Error al enviar el correo de recuperación. Intenta nuevamente.");
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      isRecovering ? handlePasswordRecovery() : handleSignUp();
    }
  };

  return (
    <div className="w-full min-h-screen flex flex-col items-center bg-gray-200">
      <div className="w-full max-w-6xl mx-auto flex flex-col items-center px-4 md:px-8"> {/* Añadido padding lateral */}
        <div className="w-full flex flex-col md:flex-row justify-between items-center gap-6">
          <div className="w-full md:w-1/2 flex flex-col justify-start items-center">
            {logo && (
              <div className="w-3/4 md:w-1/2 flex justify-center mb-2">
                <img src={logo} alt="Logo" title={empresaNombre} className="w-[350px] h-[220px] object-contain" />
              </div>
            )}
            <div className="w-full max-w-sm p-6 bg-white shadow-md rounded-md">
              <div className="flex flex-col gap-4">
                <h1 className="font-titleFont text-2xl font-semibold text-center">
                  {isRecovering ? "Recuperar Contraseña" : "Ingrese sus datos."}
                </h1>
                <div className="flex flex-col gap-1">
                  <label className="font-titleFont text-base font-semibold text-gray-500" htmlFor="email">
                    Correo Electrónico
                  </label>
                  <input
                    id="email"
                    onChange={handleEmail}
                    value={email}
                    onKeyPress={handleKeyPress}
                    className="w-full h-9 px-4 text-base border rounded-md border-gray-400 outline-none"
                    type="email"
                    placeholder="email@dominio.com"
                  />
                  {errEmail && <p className="text-sm text-red-500">{errEmail}</p>}
                </div>
                {!isRecovering && (
                  <div className="flex flex-col gap-1 relative">
                    <label className="font-titleFont text-base font-semibold text-gray-500" htmlFor="password">
                      Contraseña
                    </label>
                    <div className="relative">
                      <input
                        id="password"
                        onChange={handlePassword}
                        value={password}
                        onKeyPress={handleKeyPress}
                        className="w-full h-9 px-4 text-base border rounded-md border-gray-400 outline-none"
                        type={showPassword ? "text" : "password"}
                        placeholder="Ingrese su Clave"
                      />
                      {password.length > 0 && (
                        <button
                          type="button"
                          onClick={() => setShowPassword(!showPassword)}
                          className="absolute inset-y-0 right-4 flex items-center text-gray-600 hover:text-gray-900"
                        >
                          {showPassword ? <FaEyeSlash /> : <FaEye />}
                        </button>
                      )}
                    </div>
                    {errPassword && <p className="text-sm text-red-500">{errPassword}</p>}
                  </div>
                )}
                <button
                  onClick={isRecovering ? handlePasswordRecovery : handleSignUp}
                  className={`w-full text-base h-10 rounded-md duration-300 ${
                    email && (!isRecovering ? password : true)
                      ? "bg-primeColor hover:bg-black text-gray-200 hover:text-white cursor-pointer"
                      : "bg-gray-400 text-gray-600 cursor-not-allowed"
                  }`}
                  disabled={!email || (!isRecovering && !password)}
                >
                  {isRecovering ? "Enviar Enlace" : "Ingresar"}
                </button>
                <div className="text-center text-sm">
                  <Link to="/signup" className="text-gray-600 hover:text-blue-700 cursor-pointer">Regístrese</Link>{" "}
                  {" | "}
                  <Link to="/Forget" className="text-gray-600 hover:text-blue-700 cursor-pointer">¿Olvidaste tu contraseña?</Link>
                </div>
                {errorMsg && <p className="text-sm text-red-500">{errorMsg}</p>}
                {successMsg && <p className="text-sm text-green-500">{successMsg}</p>}
              </div>
            </div>
          </div>
          <div className="w-full md:w-1/2">
            <ProductOferta />
          </div>
        </div>
        <div className="w-full mt-2">
          <ProductNovedades />
        </div>
  
        <div className="w-full text-center mt-6 py-4 bg-gray-200">
          <p className="text-sm text-gray-600">
            Copyright © {new Date().getFullYear()} HiNet Sistemas Todos los derechos reservados.
          </p>
        </div>
      </div>
    </div>
  );
  
  
  
};

export default SignIn;

