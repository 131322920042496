import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";
import { sendOrder } from "../../constants/order";
import Swal from "sweetalert2";
import { resetCart } from "../../redux/orebiSlice"; // Importa resetCart
import { loadUserConfig } from "../../constants/config";

const Payment = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const products = useSelector((state) => state.orebiReducer.products);
  const [empresaNombre, setEmpresaNombre] = useState("");
  const [selectedPayment, setSelectedPayment] = useState("");

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await loadUserConfig();
        if (config?.empresa?.id) {
          setEmpresaNombre(config.empresa.id); // Guardar el nombre de la empresa
        }
      } catch (error) {
        console.error("Error al cargar configuración de usuario:", error);
      }
    };

    fetchConfig();
  }, []);

  const handleProcessOrder = async () => {
    const loggedUserData = JSON.parse(localStorage.getItem("user"));
    if (!loggedUserData || !loggedUserData.data || !loggedUserData.data.user) {
      console.log("Usuario no autenticado, redirigiendo a SignIn.");
      navigate("/signin");
      return;
    }
  
    const user = loggedUserData.data.user;
  
    try {
      // Enviar la orden y esperar la respuesta
      const response = await sendOrder(user, products, empresaNombre);
  
      if (!response) {
        Swal.fire({
          icon: "error",
          title: "Hubo un error en el pedido",
          text: "Inténtalo de nuevo más tarde.",
        });
      } else {
        // Usa resetCart para vaciar el carrito
        dispatch(resetCart());
        Swal.fire({
          icon: "success",
          title: response.message || "Orden procesada exitosamente",
          text: "Volver a la Tienda...",
        }).then(() => {
          navigate("/");
        });
      }
    } catch (error) {
      console.error("Error al procesar la orden:", error);
      Swal.fire({
        icon: "error",
        title: "Hubo un error al procesar la orden",
        text: "Inténtalo de nuevo más tarde.",
      });
    }
  };
  const handlePaymentSelection = (paymentType) => {
    setSelectedPayment(paymentType);
  };

  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Forma de Pago" />
      <div className="pb-10">
        {!selectedPayment && (
          <div className="flex space-x-4">
            <button
              className="w-52 h-10 bg-blue-500 text-white text-lg mt-4 hover:bg-black duration-300"
              onClick={() => handlePaymentSelection("MercadoPago")}
            >
              Mercado Pago
            </button>
            <button
              className="w-72 h-10 bg-gray-500 text-white text-lg mt-4 hover:bg-black duration-300"
              onClick={() => handlePaymentSelection("Vendedor")}
            >
              Arreglar con el vendedor
            </button>
          </div>
        )}
        {selectedPayment && (
          <button
            className="w-52 h-10 bg-green-500 text-white text-lg mt-4 hover:bg-black duration-300"
            onClick={handleProcessOrder}
          >
            Finalizar
          </button>
        )}
      </div>
    </div>
  );
};

export default Payment;
