import React, { useState, useEffect } from 'react';
import { FaShoppingCart, FaBell } from "react-icons/fa";
import { aviso } from "../../../assets/images/index";
import { useLocation } from 'react-router-dom';
import { useSelector } from "react-redux";
import DataTable from 'react-data-table-component';
import { formatPrice} from "../../../constants/funciones";
import { notices} from "../../../constants/products";
import Swal from 'sweetalert2';

const ProductTable = ({ products, handleImageClick, handleQuantityChange, handleAddToCart }) => {
  const location = useLocation();
  const isNewsPage = location.pathname === "/News" || location.pathname === "/Offer";
  const products2 = useSelector((state) => state.orebiReducer.products);

  const [quantities, setQuantities] = useState({});
  const [previousQuantities, setPreviousQuantities] = useState({});


 // Actualiza las cantidades iniciales
 useEffect(() => {
  const initialQuantities = products.reduce((acc, product) => {
    const matchedProduct = products2.find(p => p.codigo === product.codigo);
    acc[product.codigo] = matchedProduct ? matchedProduct.quantity : 0;
    return acc;
  }, {});
  setQuantities(initialQuantities);
  setPreviousQuantities(initialQuantities); // Inicializa las cantidades anteriores
}, [products, products2]);


const handleStockAlertClick = (codigo) => {
  // Obtén el usuario desde localStorage
const loggedUserData = JSON.parse(localStorage.getItem("user"));
const userId = loggedUserData?.data?.user?.id; // Obtén el ID del usuario

   Swal.fire({
       title: 'Notificarme Ingreso de Stock',
       text: '¿Quieres que te notifiquemos cuando haya stock disponible?',
       icon: 'info',
       showCancelButton: true,
       confirmButtonText: 'Ok',
       cancelButtonText: 'Cancelar',
     }).then((result) => {
       if (result.isConfirmed) {
 
  notices(codigo, userId, "0")
  .then(data => {
    Swal.fire('¡Listo!', 'Te notificaremos cuando haya stock.', 'success');
  })
  .catch(error => {
    Swal.fire('Error', 'Hubo un problema al procesar la notificación.', 'error');
  });
       }
     });
   };
   
const handleQuantityChangeInternal = (codigo, newQuantity) => {
  const quantity = Number(newQuantity);
  setQuantities(prevQuantities => ({
    ...prevQuantities,
    [codigo]: quantity,
  }));
  handleQuantityChange(codigo, quantity);
};


const handleAddToCartInternal = (product) => {
  const { codigo } = product;
  const currentQuantity = quantities[codigo] || 0;
  const previousQuantity = previousQuantities[codigo] || 0;

  // Comprobamos si la cantidad ha cambiado
  if (currentQuantity === previousQuantity) {
    // Si no se cambió la cantidad, incrementar en 1
    const updatedQuantity = currentQuantity + 1;
    setQuantities((prev) => ({ ...prev, [codigo]: updatedQuantity }));
    setPreviousQuantities((prev) => ({ ...prev, [codigo]: updatedQuantity }));

    // Llamamos a handleAddToCart con la cantidad actualizada
    handleAddToCart(product, updatedQuantity);
  } else {
    // Si se cambió, usamos la cantidad actual
    setPreviousQuantities((prev) => ({ ...prev, [codigo]: currentQuantity }));

    // Llamamos a handleAddToCart con la cantidad actual
    handleAddToCart(product, currentQuantity);
  }
};


const columns = [
  {
    name: 'Foto',
    cell: row => (
      <img
        src={row.img}
        alt={row.Descripcion}
        className="object-cover cursor-pointer"
        style={{ width: '40px', height: '40px' }}
        onClick={() => handleImageClick(row)}
      />
    ),
  },
  {
    name: <div style={{ width: '100%', textAlign: 'left' }}>Código</div>,
    cell: row => (
      <div
        style={{
          cursor: 'pointer',
          width: '100%',
          textAlign: 'left',
          fontSize: '10px',
        }}
        onClick={() => handleImageClick(row)}
      >
        {row.codigo}
      </div>
    ),
    sortable: true,
  },
  {
    name: <div style={{ width: '100%' }}>Descripcion</div>,
    cell: row => (
      <div
        style={{ cursor: 'pointer', width: '100%' }}
        onClick={() => handleImageClick(row)}
      >
        {row.Descripcion}
      </div>
    ),
    sortable: true,
    wrap: true,
  },
  {
    name: <div style={{ textAlign: 'right', width: '100%' }}>IVA</div>,
    selector: row => row.iva,
    cell: row => <div style={{ textAlign: 'right', width: '100%' }}>{row.iva}</div>,
  },
  {
    name: <div style={{ textAlign: 'right', width: '100%' }}>Precio</div>,
    cell: row => (
      <div style={{ textAlign: 'right', width: '100%' }}>
        <span
          className="precio-lista"
          style={{
            textDecoration: row.PrecioOferta > 0 ? 'line-through' : 'none',
            fontSize: '12px',
          }}
        >
          {row.simbolo} {formatPrice(row.PrecioLista || 0)}
        </span>
        {row.PrecioOferta > 0 && (
          <div>
            <span
              className="precio-oferta"
              style={{
                fontWeight: 'bold',
                fontSize: '12px',
                display: 'block',
                marginTop: '2px',
              }}
            >
              {row.simbolo} {formatPrice(row.PrecioOferta || 0)}
            </span>
          </div>
        )}
      </div>
    ),
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const priceA = rowA.PrecioOferta > 0 ? rowA.PrecioOferta : rowA.PrecioLista || 0;
      const priceB = rowB.PrecioOferta > 0 ? rowB.PrecioOferta : rowB.PrecioLista || 0;
      return priceA - priceB;
    },
  },
  {
    name: <div style={{ textAlign: 'right', width: '100%' }}>Stock</div>,
    cell: row => (
      <div
        style={{
          textAlign: 'right',
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
        }}
      >
        <span>{parseInt(row.Stock, 10)}</span>
        {parseInt(row.Stock, 10) <= 0 && (
          <FaBell
            style={{
              fontSize: '12px',
              cursor: 'pointer',
              marginLeft: '8px',
              color: '#FFCC00', 
            }}
            onClick={() => handleStockAlertClick(row.codigo)}
          />
        )}
      </div>
    ),
    sortable: true,
  },  
  {
    name: 'Condicion',
    selector: row => row.atributo,
    sortable: true,
    wrap: true,
  },
  {
    name: 'Grupo',
    selector: row => row.grupo,
    sortable: true,
    wrap: true,
  },
  {
    name: 'Marca',
    selector: row => row.marca,
    sortable: true,
    wrap: true,
  },
  ...(isNewsPage
    ? []
    : [
        {
          name: 'Cantidad',
          cell: row => (
            <input
              type="number"
              id={`quantity-input-${row.codigo}`}
              min="0"
              value={quantities[row.codigo] || 0}
              onChange={e => handleQuantityChangeInternal(row.codigo, e.target.value)}
              className="w-10 border border-gray-300 rounded text-right"
              style={{ fontSize: '10px', textAlign: 'right' }}
            />
          ),
          ignoreRowClick: true,
        },
        {
          name: 'Agregar',
          cell: row => (
            <button
              onClick={() => handleAddToCartInternal(row)}
              className="bg-blue-500 text-white px-1 py-0.5 rounded hover:bg-blue-700"
              style={{ fontSize: '10px' }}
            >
              <FaShoppingCart />
            </button>
          ),
          ignoreRowClick: true,
        },
      ]),
];

  
  
  

  return (
    <DataTable
    columns={columns}
    data={products}
    customStyles={{
      rows: {
        style: {
          minHeight: '50px',
        },
      },
      headCells: {
        style: {
          fontSize: '12px',
          fontWeight: 'bold',
          paddingLeft: '8px',
          paddingRight: '8px',
          backgroundColor: '#f7fafc',
        },
      },
      cells: {
        style: {
          fontSize: '11px',
          paddingLeft: '8px',
          paddingRight: '8px',
          whiteSpace: 'normal',
          overflow: 'visible',

        },
      },
    }}

  />

  );
};

export default ProductTable;
