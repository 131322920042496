import React, { useState } from 'react';
import { FaTrash } from "react-icons/fa";
import { useLocation } from 'react-router-dom';
import Swal from 'sweetalert2';
import { notices } from "../../../constants/products";

const ProductTable2 = ({ products: initialProducts }) => {
  const [products, setProducts] = useState(initialProducts);
  const location = useLocation();
  const isNewsPage = location.pathname === "/Receive";

  const handleDelete = (codigo) => {
    const loggedUserData = JSON.parse(localStorage.getItem("user"));
    const userId = loggedUserData?.data?.user?.id;

    if (!userId) {
      Swal.fire('Error', 'No se pudo obtener el usuario. Intenta iniciar sesión nuevamente.', 'error');
      return;
    }

    notices(codigo, userId, "1")
      .then(() => {
        setProducts((prevProducts) => prevProducts.filter(product => product.codigo !== codigo));
        Swal.fire('¡Eliminado!', 'El producto ha sido eliminado correctamente.', 'success');
      })
      .catch((error) => {
        console.error("Error al eliminar el producto:", error);
        Swal.fire('Error', 'Hubo un problema al eliminar el producto. Intenta de nuevo más tarde.', 'error');
      });
  };

  return (
    <div className="product-list">
      <ul style={{ listStyleType: 'none', padding: '0' }}>
        {products.map((product) => (
          <li key={product.codigo} style={{ display: 'flex', justifyContent: 'space-between', margin: '8px 0', padding: '8px' }}>
            <div style={{ fontSize: '14px', fontWeight: 'bold' }}>
              <span style={{ marginRight: '10px' }}>{product.codigo}</span>
              <span style={{ fontSize: '12px', fontWeight: 'normal' }}>{product.Descripcion}</span>
            </div>
            {!isNewsPage && (
              <button
                onClick={() => {
                  Swal.fire({
                    title: '¿Estás seguro?',
                    text: "Este producto será eliminado",
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Sí, borrar',
                    cancelButtonText: 'Cancelar',
                  }).then((result) => {
                    if (result.isConfirmed) {
                      handleDelete(product.codigo);
                    }
                  });
                }}
                className="text-red-500 px-2 py-1 hover:text-red-700"
                style={{
                  fontSize: '14px',
                  marginLeft: '15px', 
                }}
              >
                <FaTrash />
              </button>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ProductTable2;
