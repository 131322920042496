import React, { useState } from "react";

const Forget = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (email) {
      setMessage("Si el correo existe en nuestro sistema, recibirás instrucciones para recuperar tu contraseña.");
      setEmail(""); // Limpia el campo del email
    } else {
      setMessage("Por favor, ingresa un correo válido.");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "#f7f9fc",
      }}
    >
      <div
        style={{
          maxWidth: "400px",
          width: "100%",
          background: "#fff",
          padding: "30px",
          borderRadius: "10px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
          textAlign: "center",
        }}
      >
        <h2 style={{ marginBottom: "20px", color: "#333" }}>¿Olvidaste tu contraseña?</h2>
        <p style={{ marginBottom: "20px", color: "#666" }}>
          Ingresa tu correo electrónico para recibir un enlace de recuperación.
        </p>
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Correo electrónico"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            style={{
              width: "100%",
              padding: "12px",
              marginBottom: "15px",
              border: "1px solid #ddd",
              borderRadius: "5px",
              fontSize: "16px",
            }}
          />
          <button
            type="submit"
            style={{
              width: "100%",
              padding: "12px",
              backgroundColor: "#007bff",
              color: "#fff",
              border: "none",
              borderRadius: "5px",
              fontSize: "16px",
              cursor: "pointer",
            }}
          >
            Enviar
          </button>
        </form>
        {message && (
          <p style={{ marginTop: "20px", color: "#28a745", fontSize: "14px" }}>
            {message}
          </p>
        )}
      </div>
    </div>
  );
};

export default Forget;
