import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ProductInfo from "../../components/pageProps/productDetails/ProductInfo";
import ProductsOnSale from "../../components/pageProps/productDetails/ProductsOnSale";
import { loadProduct, paginationItems6 } from "../../constants/products";

const ProductDetails = () => {
  const location = useLocation();
  const [productInfo, setProductInfo] = useState({});
  const [product, setProduct] = useState(null); // Use `null` to differentiate between initialized and empty data

  useEffect(() => {
    if (location.state?.item) {
      setProductInfo(location.state.item); // Set productInfo from location state
    }
  }, [location]);

  useEffect(() => {
    const fetchProduct = async () => {
      if (productInfo.codigo && productInfo.Descripcion) {
        try {
          // Fetch product data based on productInfo.codigo
          await loadProduct(productInfo.codigo); 
          setProduct(paginationItems6[0]); // Assuming paginationItems6 is populated with the product list
        } catch (error) {
          console.error("Error al cargar el producto:", error);
        }
      }
    };

    fetchProduct();
  }, [productInfo]);

  return (
    <div className="w-full mx-auto border-b-[1px] border-b-gray-300">
      <div className="max-w-container mx-auto px-4">
        <div className="w-full flex flex-col gap-8 h-full pb-10 p-4">
          {/* Main Section: Image, Description, and Details */}
          <div className="grid grid-cols-1 md:grid-cols-3 gap-12 w-full">
            {/* Product Image */}
            <div className="flex justify-center">
              <img
                className="object-cover"
                style={{ width: "350px", height: "350px" }}
                src={product?.img } // Fallback image if product.img is missing
                alt={product?.Descripcion} // Fallback alt text
              />
            </div>

            {/* Product Description */}
            <div className="flex flex-col gap-4">
              <ProductInfo productInfo={product} />
            </div>

            {/* Additional Details */}
            {product?.datos_adicionales && (
              <div className="flex flex-col gap-4">
                <h2 className="text-xl font-semibold">Detalles Adicionales</h2>
                <p className="text-gray-700 p-4">{product.datos_adicionales}</p>
              </div>
            )}
          </div>

          {/* Related Products Section */}
          {product?.productos_relacionados?.length > 0 && (
            <div className="w-full mt-10">
              <ProductsOnSale relatedProducts={product.productos_relacionados} />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProductDetails;
