import React from "react";
import { BsSuitHeartFill } from "react-icons/bs";
import { GiReturnArrow } from "react-icons/gi";
import { FaShoppingCart } from "react-icons/fa";
import { MdOutlineLabelImportant } from "react-icons/md";
import Image from "../../designLayouts/Image";
import Badge from "./Badge";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";
import { formatPrice} from "../../../constants/funciones";
const Product = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const idString = (name) => {
    return String(name)
      .toLowerCase()
      .replace(/\s+/g, "") // Reemplaza espacios por nada
      .replace(/[^\w-]/g, ""); // Elimina caracteres no válidos (incluido '/')
  };
  
  const rootId = idString(props.Descripcion);

  const handleProductDetails = () => {
    navigate(`/product/${rootId}`, {
      state: { item: props }, // Envía el objeto de producto completo
    });
  };
console.log(props);
  return (
    <div className="w-full relative group border rounded-lg overflow-hidden shadow-md hover:shadow-lg transition-shadow duration-300">
     <div className="w-full relative overflow-hidden">
    {/* Imagen responsiva */}
    <Image className="w-full h-auto object-cover" imgSrc={props.img} />
      
        <div className="absolute bottom-0 w-full bg-white transition-transform duration-700 transform translate-y-full group-hover:translate-y-0">
          <ul className="flex flex-col gap-2 font-titleFont px-2">
            <li className="text-[#767676] hover:text-primeColor text-sm border-b border-b-gray-200 flex items-center justify-between pb-1 cursor-pointer duration-300">
              Compartir
              <GiReturnArrow />
            </li>
            <li
              onClick={() =>
                dispatch(
                  addToCart({              
                    ...props,
                    quantity: 1,            
                  })
                )
              }
              className="text-[#767676] hover:text-primeColor text-sm border-b border-b-gray-200 flex items-center justify-between pb-1 cursor-pointer duration-300"
            >
              Agregar al Carrito
              <FaShoppingCart />
            </li>
            <li
              onClick={handleProductDetails} // Maneja el clic para ver detalles
              className="text-[#767676] hover:text-primeColor text-sm border-b border-b-gray-200 flex items-center justify-between pb-1 cursor-pointer duration-300"
            >
              Ver Detalles
              <MdOutlineLabelImportant className="text-lg" />
            </li>
            <li className="text-[#767676] hover:text-primeColor text-sm border-b border-b-gray-200 flex items-center justify-between pb-1 cursor-pointer duration-300">
              Agregar a Favoritos
              <BsSuitHeartFill />
            </li>
          </ul>
        </div>
      </div>
      <div className="max-w-80 py-4 flex flex-col gap-1 border-t border-gray-200 px-4">
        <p className="text-[#767676] text-[14px]">Código: {props.codigo}</p> {}
        <h2 className="text-lg text-primeColor font-bold">
          {props.Descripcion}
        </h2>
        <p className="text-[#767676] text-[14px]">
  {props.PrecioOferta > 0 ? (
    <>
      <span style={{ textDecoration: 'line-through' }}>
        ${formatPrice(props.PrecioLista)}
      </span> 
      &nbsp;
      <span>${formatPrice(props.PrecioOferta)}</span>
    </>
  ) : (
    `$${formatPrice(props.PrecioLista)}`
  )}
</p>

      </div>
    </div>
  );
};

export default Product;
