
  /**PRODUCTOS */
export let paginationItems = []; //productos
export let paginationItems2 = []; //novedades
export let paginationItems3 = []; //novedades
export let paginationItems4 = []; //novedades
export let paginationItems5 = []; //novedades
export let paginationItems6 = []; //novedades
export let paginationMeta = {}; //productos
export let paginationMeta2 = {}; //novedades
export let paginationMeta3 = {}; //novedades
export let paginationMeta4 = {}; //novedades
export let paginationMeta5 = {}; //novedades

/**AVISOS */
export const loadReceive= async (pagina, totalpage, like_text = "", idgrupo = 0, idtipo = 0, idmarca = 0) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_URL_APIHN24}/aviso_entrante/${process.env.REACT_APP_DB_EMPRESA}?page=${pagina}&total_page=${totalpage}&like_text=${like_text}&idgrupo=${idgrupo}&idmarca=${idmarca}&atributo1=${idtipo}`, {
      method: "GET",
      headers: {
        'Authorization': 'Basic ' + btoa('hinetService:99RHinet202'), // Autenticación básica
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error al cargar los productos");
    }

    const data = await response.json();

    // Formatear productos y asignar a paginationItems
    paginationItems5 = data.data.map(product => ({
    
      codigo: product.codigo,
      Descripcion: product.descrip1,
      imagen:product.rimagen,
      fecha_entrega:product.fecha_entrega,
      }));
      
      paginationMeta5 = {
        currentPage: data.meta.pagina_actual,
        perPage: data.meta.por_pagina,
        totalRecords: data.meta.total_registros,
        totalPages: data.meta.total_pagina
      };

  } catch (error) {
    console.error("Error fetching products:", error);
  }
};

/**AVISOS */
export const loadNotices = async (pagina, totalpage, like_text = "", idgrupo = 0, idtipo = 0, idmarca = 0) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_URL_APIHN24}/aviso_detalle/${process.env.REACT_APP_DB_EMPRESA}?page=${pagina}&total_page=${totalpage}&like_text=${like_text}&idgrupo=${idgrupo}&idmarca=${idmarca}&atributo1=${idtipo}`, {
      method: "GET",
      headers: {
        'Authorization': 'Basic ' + btoa('hinetService:99RHinet202'), // Autenticación básica
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error al cargar los productos");
    }

    const data = await response.json();

    // Formatear productos y asignar a paginationItems
    paginationItems4 = data.data.map(product => ({
      codigo: product.codigo,
      Descripcion: product.descrip1,
      }));
      
      paginationMeta4 = {
        currentPage: data.meta.pagina_actual,
        perPage: data.meta.por_pagina,
        totalRecords: data.meta.total_registros,
        totalPages: data.meta.total_pagina
      };

  } catch (error) {
    console.error("Error fetching products:", error);
  }
};


export const notices = async (codigo, idusuario, borrar) => {
  try {
    if (borrar !== "0" && borrar !== "1") {
      console.warn("Valor de 'borrar' no válido:", borrar);
      return { message: "Operación no válida: borrar debe ser 0 o 1" };
    }

    const body = {
      empresa: process.env.REACT_APP_DB_EMPRESA,
      idusuario,
      codigo,
      borrar,
    };

    console.log("Datos enviados:", body);

    const response = await fetch(`${process.env.REACT_APP_URL_APIHN24}/aviso_entrante`, {
      method: "POST",
      headers: {
        'Authorization': 'Basic ' + btoa('hinetService:99RHinet202'),
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(body),
    });

    if (!response.ok) {
      let errorMessage = "Error en la solicitud";
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || errorMessage;
      } catch (parseError) {
        console.error("Error al procesar la respuesta del servidor:", parseError);
      }
      throw new Error(errorMessage);
    }

    // Si borrar es 1, solo confirmamos la operación y no devolvemos nada.
    if (borrar === 1) {
      console.log("Aviso borrado exitosamente.");
      return; // Finaliza sin retornar datos.
    }

    // Si borrar es 0, retornamos la lista de avisos.
    const data = await response.json();
    console.log("Lista de avisos obtenida:", data);
    return data;

  } catch (error) {
    console.error("Error en notices:", error.message);
    throw error;
  }
};

export const emailpedido = async (nro_interno, email) => {
  try {
    // Verifica si nro_interno es 0 y retorna sin hacer nada
    if (nro_interno === 0) {
      console.log("No se envió el email porque nro_interno es 0.");
      return;
    }

    // Datos requeridos por la API
    const body = {
      empresa: process.env.REACT_APP_DB_EMPRESA, // Asegúrate de que esté configurada en tu archivo .env
      nro_interno, // Número interno del pedido
      email, // Correo del cliente
    };

    // Solicitud a la API
    const response = await fetch(`${process.env.REACT_APP_URL_APIHN24}/enviar_email`, {
      method: "POST",
      headers: {
        'Authorization': 'Basic ' + btoa('hinetService:99RHinet202'), // Autorización básica
        'Content-Type': 'application/json', // Indica que el contenido es JSON
      },
      body: JSON.stringify(body), // Convierte el cuerpo de la solicitud a JSON
    });

    // Verifica si la respuesta fue exitosa
    if (!response.ok) {
      let errorMessage = "Error en la solicitud";
      try {
        const errorData = await response.json();
        errorMessage = errorData.message || errorMessage;
      } catch (parseError) {
        console.error("Error al procesar la respuesta del servidor:", parseError);
      }
      throw new Error(errorMessage);
    }

    // Procesa la respuesta JSON
    const data = await response.json();
    console.log("Email enviado con éxito:", data);
    return data;

  } catch (error) {
    // Manejo de errores
    console.error("Error en emailpedido:", error.message);
    throw error;
  }
};


//*PRODUCTOS*/ */
export const loadProducts = async (pagina, totalpage, like_text = "", idgrupo = 0, idtipo = 0, idmarca = 0) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_URL_APIHN24}/productos/${process.env.REACT_APP_DB_EMPRESA}?page=${pagina}&total_page=${totalpage}&like_text=${like_text}&idgrupo=${idgrupo}&idmarca=${idmarca}&atributo1=${idtipo}`, {
      method: "GET",
      headers: {
        'Authorization': 'Basic ' + btoa('hinetService:99RHinet202'), // Autenticación básica
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error al cargar los productos");
    }

    const data = await response.json();

    // Formatear productos y asignar a paginationItems
    paginationItems = data.data.map(product => ({
        id_deposito: product.id_deposito || "01",
        codigo: product.codigo,
        Descripcion: product.descrip1,
        Stock: product.stock,
        stock_mensaje:product.stock_mensaje,
        moneda: product.moneda || "PESO",
        PrecioLista: product.precioanterior,
        PrecioOferta: product.preciooferta,
        img: product.rimagen,
        iva:product.tasa_iva,
        simbolo: product.simbolo,
        precio: product.precioprod,
        atributo: product.atributo1,
        grupo: product.grupo,
        marca: product.marca,
        datos_adicionales: product.datos_adicionales,
        productos_relacionados: product.prod_relacionados.length > 0 ? product.prod_relacionados.map(relacion => ({
          codigo_master: relacion.codigo_master,
          codigo: relacion.codigo_relacionado,
          Descripcion: relacion.producto_relacionado,
          img: relacion.rimagen, 
          simbolo: product.simbolo,
          PrecioLista:relacion.PrecioVentaRelacionado,
          PrecioOferta:relacion.PrecioOfertaRelacionado,
          datos_adicionales: relacion.datos_adicionales,
        })) : [] 
      }));
      
      paginationMeta = {
        currentPage: data.meta.pagina_actual,
        perPage: data.meta.por_pagina,
        totalRecords: data.meta.total_registros,
        totalPages: data.meta.total_pagina
      };

  } catch (error) {
    console.error("Error fetching products:", error);
  }
};


/**PRODUCTO ESPECIFICO */
export const loadProduct = async (codigo) => {
  try {
    const response = await fetch(
      `${process.env.REACT_APP_URL_APIHN24}/producto/${process.env.REACT_APP_DB_EMPRESA}/${codigo}`, // Corrected the URL
      {
        method: "GET",
        headers: {
          Authorization: 'Basic ' + btoa('hinetService:99RHinet202'),
          'Content-Type': 'application/json',
        },
      }
    );

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error al cargar los productos");
    }

    const data = await response.json();

    // Formatear productos y asignar a paginationItems
    paginationItems6 = data.data.map(product => ({
        id_deposito: product.id_deposito || "01",
        codigo: product.codigo,
        Descripcion: product.descrip1,
        Stock: product.stock,
        stock_mensaje: product.stock_mensaje,
        moneda: product.moneda || "PESO",
        PrecioLista: product.precioanterior,
        PrecioOferta: product.preciooferta,
        img: product.rimagen,
        iva: product.tasa_iva,
        precio: product.precioprod,
        atributo: product.atributo1,
        simbolo: product.simbolo,
        grupo: product.grupo,
        marca: product.marca,
        datos_adicionales: product.datos_adicionales,
        productos_relacionados: product.prod_relacionados.length > 0 ? product.prod_relacionados.map(relacion => ({
          codigo_master: relacion.codigo_master,
          codigo: relacion.codigo_relacionado,
          Descripcion: relacion.producto_relacionado,
          img: relacion.rimagen, 
          simbolo: product.simbolo,
          PrecioLista: relacion.PrecioVentaRelacionado,
          PrecioOferta: relacion.PrecioOfertaRelacionado,
          datos_adicionales: relacion.datos_adicionales,
        })) : [] 
      }));

  } catch (error) {
    console.error("Error fetching products:", error);
    throw error; // Asegurar que el error se propague
  }
};



/*NOVEDADES*/
export const loadNews = async (pagina, totalpage, like_text = "", idgrupo = 0, idtipo = 0, idmarca = 0) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_URL_APIHN24}/productos_novedades/${process.env.REACT_APP_DB_EMPRESA}?page=${pagina}&total_page=${totalpage}&like_text=${like_text}&idgrupo=${idgrupo}&idmarca=${idmarca}&atributo1=${idtipo}`, {
      method: "GET",
      headers: {
        'Authorization': 'Basic ' + btoa('hinetService:99RHinet202'), // Basic authentication
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error al cargar los productos");
    }

    const data = await response.json();

    // Map the products and assign to paginationItems2
    paginationItems2 = data.data.map(product => ({
      id_deposito: product.id_deposito || "01",
      codigo: product.codigo,
      Descripcion: product.descrip1,
      Stock: product.stock,
      stock_mensaje:product.stock_mensaje,
      moneda: product.moneda || "PESO",
      PrecioLista: product.precioanterior,
      PrecioOferta: product.preciooferta,
      img: product.rimagen,
      simbolo: product.simbolo,
      iva:product.tasa_iva,
      precio: product.precioprod,
      atributo: product.atributo1,
      grupo: product.grupo,
      marca: product.marca,
      datos_adicionales: product.datos_adicionales,
      productos_relacionados: product.prod_relacionados.length > 0 ? product.prod_relacionados.map(relacion => ({
        codigo_master: relacion.codigo_master,
        codigo_relacionado: relacion.codigo_relacionado,
        descripcion: relacion.producto_relacionado,
        imagen: relacion.rimagen, 
        simbolo: product.simbolo,
        precio:relacion.PrecioVentaRelacionado,
        oferta:relacion.PrecioOfertaRelacionado,
      })) : [] 
    }));

    paginationMeta2 = {
      currentPage: data.meta.pagina_actual,
      perPage: data.meta.por_pagina,
      totalRecords: data.meta.total_registros,
      totalPages: data.meta.total_pagina
    };

  } catch (error) {
    console.error("Error fetching products:", error);
  }
};


/**OFERTAS */
export const loadOffer= async (pagina, totalpage, like_text = "", idgrupo = 0, idtipo = 0, idmarca = 0) => {
  try {
    const response = await fetch(`${process.env.REACT_APP_URL_APIHN24}/productos_oferta/${process.env.REACT_APP_DB_EMPRESA}?page=${pagina}&total_page=${totalpage}&like_text=${like_text}&idgrupo=${idgrupo}&idmarca=${idmarca}&atributo1=${idtipo}`, {
      method: "GET",
      headers: {
        'Authorization': 'Basic ' + btoa('hinetService:99RHinet202'), // Autenticación básica
        'Content-Type': 'application/json'
      }
    });

    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Error al cargar los productos");
    }

    const data = await response.json();

    // Formatear productos y asignar a paginationItems
    paginationItems3 = data.data.map(product => ({
      id_deposito: product.id_deposito || "01",
        codigo: product.codigo,
        Descripcion: product.descrip1,
        Stock: product.stock,
        stock_mensaje:product.stock_mensaje,
        moneda: product.moneda || "PESO",
        PrecioLista: product.precioanterior,
        PrecioOferta: product.preciooferta,
        img: product.rimagen,
        iva:product.tasa_iva,
        precio: product.precioprod,
        simbolo: product.simbolo,
        atributo: product.atributo1,
        grupo: product.grupo,
        marca: product.marca,
        datos_adicionales: product.datos_adicionales,
        productos_relacionados: product.prod_relacionados.length > 0 ? product.prod_relacionados.map(relacion => ({
          codigo_master: relacion.codigo_master,
          codigo_relacionado: relacion.codigo_relacionado,
          descripcion: relacion.producto_relacionado,
          imagen: relacion.rimagen, 
          simbolo: product.simbolo,
          precio:relacion.PrecioVentaRelacionado,
          oferta:relacion.PrecioOfertaRelacionado,
        })) : [] 
      }));

      paginationMeta3 = {
        currentPage: data.meta.pagina_actual,
        perPage: data.meta.por_pagina,
        totalRecords: data.meta.total_registros,
        totalPages: data.meta.total_pagina
      };

  } catch (error) {
    console.error("Error fetching products:", error);
  }
};