import React, { useContext, useEffect, useState } from "react";
import { UserConfigContext } from "../../../context/UserConfigContext";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import SwiperCore, { Autoplay } from "swiper";

// Activa el módulo Autoplay en Swiper
SwiperCore.use([Autoplay]);

const ProductNovedades = () => {
  const [brands, setBrands] = useState([]);
  const config = useContext(UserConfigContext);

  useEffect(() => {
    if (config && config.marcas_imagenes) {
      setBrands(config.marcas_imagenes);
    }
  }, [config]);

  return (
    <div className="w-full mx-auto">
      <Swiper
        spaceBetween={30}
        autoplay={{
          delay: 1500,
          disableOnInteraction: false,
        }}
        loop={true}
        breakpoints={{
          640: {
            slidesPerView: 2, // En pantallas pequeñas
          },
          768: {
            slidesPerView: 3, // En pantallas medianas
          },
          1024: {
            slidesPerView: 5, // En pantallas grandes
          },
        }}
      >
        {brands.map((brand) => (
          <SwiperSlide key={brand.clave_id} className="p-4">
            <div className="flex flex-col items-center gap-4">
             
                <img
                  className="object-cover"
                  src={brand.imagen}
                  style={{
                    width: "200px",
                    height: "125px",
                  }}
                 
                />
            
              <p className="text-center text-gray-700 mt-2 whitespace-nowrap overflow-hidden text-ellipsis">
                {brand.descripcion}
              </p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default ProductNovedades;
