import { Navigate } from "react-router-dom";

// Componente para rutas protegidas
const ProtectedRoute = ({ element }) => {
  const user = JSON.parse(localStorage.getItem("user")); // Obtener el usuario del localStorage

  // Si no hay usuario autenticado, redirige a la página de inicio de sesión
  if (!user) {
    return <Navigate to="/signin" />;
  }

  return element; // Si hay usuario, renderiza el elemento
};

export default ProtectedRoute;
