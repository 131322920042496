import React, { useState } from "react";
/*import Banner from "../../components/Banner/Banner";*/
/* productos */
import PaginationNot from "../../components/pageProps/shopPage/PaginationNot";

const Receive = () => {
  const [itemsPerPage, setItemsPerPage] = useState(12);
  
  return (
    <div className="w-full mx-auto">
      <div className="max-w-container mx-auto px-4">
      <h1 className="text-4xl font-bold mt-6 mb-4"> Proximos Ingresos</h1>
        {/* ================= Products Start here =================== */}
        <div className="w-full h-full flex pb-20 gap-10">
          <div className="w-50 h-full flex flex-col gap-10 mt-3">
            <PaginationNot itemsPerPage={itemsPerPage} />
          </div>
        </div>
        {/* ================= Products End here ===================== */}
      </div>
    </div>
  );
};

export default Receive;
