import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { resetCart } from "../../redux/orebiSlice";
import { emptyCart } from "../../assets/images/index";
import { formatPrice } from "../../constants/funciones";
import Swal from "sweetalert2";
import { loadUserConfig } from "../../constants/config";
import { sendOrder } from "../../constants/order";
import { emailpedido } from "../../constants/products";
import { ImBin } from "react-icons/im";
import {
  deleteItem,
  drecreaseQuantity,
  increaseQuantity,
} from "../../redux/orebiSlice";

const Cart = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const products = useSelector((state) => state.orebiReducer.products);
  const [totalAmt, setTotalAmt] = useState(0);
  const [shippingCharge, setShippingCharge] = useState(0);
  const [empresaId, setEmpresaId] = useState("");

  useEffect(() => {
    const fetchConfig = async () => {
      try {
        const config = await loadUserConfig();
        if (config?.empresa?.id) {
          setEmpresaId(config.empresa.id); // Guardar el ID de la empresa
        }
      } catch (error) {
        console.error("Error al cargar configuración de usuario:", error);
      }
    };

    fetchConfig();
    const price = products.reduce((acc, item) => acc + ((item.PrecioOferta || item.PrecioLista) * item.quantity), 0);
    setTotalAmt(price);
  }, [products]);

  useEffect(() => {   
      setShippingCharge(0);
  }, [totalAmt]);

  const handleProcessOrder = async () => {
    const loggedUserData = JSON.parse(localStorage.getItem("user"));
    if (!loggedUserData || !loggedUserData.data || !loggedUserData.data.user) {
      console.log("Usuario no autenticado, redirigiendo a SignIn.");
      navigate("/signin");
      return;
    }

    const user = loggedUserData.data.user;

    try {
      const response = await sendOrder(user, products, empresaId);

      if (!response) {
        Swal.fire({
          icon: "error",
          title: "Hubo un error en el pedido",
          text: "Inténtalo de nuevo más tarde.",
        });
      } else {
        // Resetear carrito y mostrar mensaje de éxito
        dispatch(resetCart());
        Swal.fire({
          icon: "success",
          title: response.message || "Orden procesada exitosamente",
          text: "Volver a la Tienda...",
        }).then(async () => {
          // Enviar el correo al cliente
          try {
            await emailpedido(response.nro_interno, user.email); // orderId devuelto por la API
            console.log("Correo enviado al cliente con éxito.");
          } catch (emailError) {
            console.error("Error al enviar el correo:", emailError.message || emailError);
            Swal.fire({
              icon: "error",
              title: "Error al enviar el correo",
              text: "El pedido fue procesado, pero no se pudo enviar el correo.",
            });
          }

          navigate("/");
        });
      }
    }  catch (error) {
      console.error("Error al procesar la orden:", error.message || error);
      Swal.fire({
        icon: "error",
        title: "Hubo un error al procesar la orden",
        text: error.response?.data?.message || "Inténtalo de nuevo más tarde.",
      });
    }
  };

  return (
    <div className="max-w-container mx-auto px-4">
      <h1 className="text-4xl font-bold mt-6 mb-4">Mi pedido</h1>
      {products.length > 0 ? (
        <div className="pb-20">
          <div className="overflow-x-auto">
          <table className=" w-full table-responsive border-collapse mb-2 text-lg">
  <thead>
    <tr className="bg-gray-100 text-primeColor">
      <th className="text-left p-2">Producto</th>
      <th className="text-right p-2">Precio</th>
      <th className="text-center p-2">Cantidad</th>
      <th className="text-right p-2">Sub Total</th>
    </tr>
  </thead>
  <tbody>
    {products.map((item) => {
      const price = item.PrecioOferta || item.PrecioLista;
      const total = item.quantity * price;

      return (
        <tr key={item.codigo} className="border-b">
          <td className="flex items-center gap-4 p-4">
            <ImBin
              onClick={() => dispatch(deleteItem(item.codigo))}
              className="text-primeColor hover:text-red-500 duration-300 cursor-pointer"
            />
            <img
              src={item.img}
              alt={item.Descripcion}
              className="w-16 h-16 object-cover cursor-pointer"
              onClick={() => navigate(`/product/${item.codigo}`, { state: { item } })}
            />
            <span className="text-center">{item.Descripcion}</span>
          </td>
          <td className="text-right p-2">{item.simbolo}{formatPrice(price)}</td>
          <td className="text-center">
            <div className="flex items-center justify-center gap-2">
              <button
                onClick={() => dispatch(drecreaseQuantity({ codigo: item.codigo }))}
                className="px-3 py-1 border rounded-lg hover:bg-gray-200 duration-200"
              >
                -
              </button>
              <span>{item.quantity}</span>
              <button
                onClick={() => dispatch(increaseQuantity({ codigo: item.codigo }))}
                 className="px-3 py-1 border rounded-lg hover:bg-gray-200 duration-200"
              >
                +
              </button>
            </div>
          </td>
          <td className="text-right p-4">{item.simbolo}{formatPrice(total)}</td>
        </tr>
      );
    })}
  </tbody>
</table>
</div>

          <button
            type="button"
            onClick={() => dispatch(resetCart())}
            className="py-2 px-10 bg-red-500 text-white font-semibold uppercase mb-4 hover:bg-red-700 duration-300"
          >
            Vaciar carrito
          </button>
          <Link to="/">
            <button className="py-2 px-10 bg-green-500 text-white font-semibold uppercase ml-4 hover:bg-green-700 duration-300">
              Seguir comprando
            </button>
          </Link>

          <div className="max-w-8xl gap-4 flex justify-end mt-4 ">
            <div className="w-96 flex flex-col gap-4">
              <h1 className="text-2xl font-semibold text-right">Total</h1>
              <div>
                <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
                  Subtotal
                  <span className="font-semibold tracking-wide font-titleFont">
                   $ {formatPrice(totalAmt)}
                  </span>
                </p>
                <p className="flex items-center justify-between border-[1px] border-gray-400 border-b-0 py-1.5 text-lg px-4 font-medium">
                  Cargo de envío
                  <span className="font-semibold tracking-wide font-titleFont">
                   $ {formatPrice(shippingCharge)}
                  </span>
                </p>
                <p className="flex items-center justify-between border-[1px] border-gray-400 py-1.5 text-lg px-4 font-medium">
                  Total
                  <span className="font-bold tracking-wide text-lg font-titleFont">
                   $ {formatPrice(totalAmt + shippingCharge)}
                  </span>
                </p>
              </div>
              <div className="flex justify-end">
                <button
                  onClick={handleProcessOrder}
                  className="w-96 h-10 bg-blue-500 text-white text-lg mt-4 hover:bg-black duration-300"
                >
                  Finalizar
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <motion.div
          initial={{ y: 30, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.4 }}
          className="flex flex-col mdl:flex-row justify-center items-center gap-4 pb-20"
        >
          <div>
            <img
              className="w-80 rounded-lg p-4 mx-auto"
              src={emptyCart}
              alt="Carrito vacío"
            />
          </div>
          <div className="max-w-[500px] p-4 py-8 bg-white flex gap-4 flex-col items-center rounded-md shadow-lg">
            <p className="text-sm text-center px-10 -mt-2">
              Tu carrito está vacío.
            </p>
            <Link to="/">
              <button
                aria-label="Volver a la tienda"
                className="bg-primeColor rounded-md cursor-pointer hover:bg-black active:bg-gray-900 px-8 py-2 font-titleFont font-semibold text-lg text-gray-200 hover:text-white duration-300"
              >
                Volver a la tienda
              </button>
            </Link>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Cart;
