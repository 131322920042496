import React, { useEffect, useState } from "react";
import { loadNews, paginationItems2 } from "../../../constants/products";
import { formatPrice } from "../../../constants/funciones";

const ProductOferta = () => {
  const [products, setProducts] = useState([]);

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        await loadNews(1, 10, "", 0, 0, 0); // Ajusta los parámetros según sea necesario
        setProducts(paginationItems2);
      } catch (error) {
        console.error("Failed to load products:", error);
      }
    };

    fetchProducts();
  }, []);

  return (
    <div className="w-full h-100 overflow-hidden relative">
      <div className="gallery">
        {products.slice(0, 6).map((item) => (
          <div key={item.codigo} className="image-container">
            <img
              className="product-image"
              src={item.img}
            />
            <div className="product-info">
              <p className="product-description">{item.Descripcion}</p>
              <div className="price-info">
                {item.PrecioOferta > 0 ? (
                  <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
                    <span
                      style={{
                        textDecoration: "line-through",
                        fontSize: "10px",
                      }}
                    >
                     {item.simbolo} {formatPrice(item.precio)}
                    </span>
                    <span style={{ fontWeight: "bold", fontSize: "12px" }}>
                    {item.simbolo} {formatPrice(item.PrecioOferta)}
                    </span>
                  </div>
                ) : (
                  <p className="text-sm font-semibold">{item.simbolo} {formatPrice(item.precio)}</p>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>

      <style>
      <style>
  {`
    .gallery {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      gap: 12px;
      height: 100%;
    }

    .image-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      background: white;
      padding: 6px;
      text-align: center;
    }

    .product-image {
      width: 180px;
      height: 180px;
      object-fit: cover;
      margin-bottom: 10px;
    }

    .product-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: space-between;
      width: 100%;
    }

    .product-description {
      font-size: 14px;
      color: #333;
      margin-top: 5px;
      height: 42px; /* Altura equivalente a dos líneas */
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      overflow: hidden;
      text-overflow: ellipsis;
      line-height: 1.4;
    }

    .price-info {
      display: flex;
      flex-direction: row;
      gap: 10px;
      align-items: center;
    }
  `}
</style>

      </style>
    </div>
  );
};

export default ProductOferta;
