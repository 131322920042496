import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";
import { formatPrice } from "../../../constants/funciones";

const ProductInfo = ({ productInfo }) => {
  const dispatch = useDispatch();
  const [quantity, setQuantity] = useState(0); // Establece un valor predeterminado
  const [previousQuantities, setPreviousQuantities] = useState({});

  const products2 = useSelector((state) => state.orebiReducer.products);

  const handleQuantityChange = (e) => {
    const value = Math.max(1, parseInt(e.target.value, 10) || 0); // Asegura que siempre sea al menos 1
    setQuantity(value);
    setPreviousQuantities(value);
  };

  // Actualiza la cantidad inicial basada en products2
  useEffect(() => {
    if (productInfo && products2) {
      const matchedProduct = products2.find(
        (p) => p.codigo === productInfo.codigo
      );
      if (matchedProduct) {
        setQuantity(matchedProduct.quantity);
      } else {
        setQuantity(); // Valor predeterminado si no se encuentra el producto
      }
    }
  }, [productInfo, products2]);

  // Verifica si productInfo está disponible
  if (!productInfo) {
    return <div>Cargando...</div>; // Indicador de carga
  }

  return (
    <div className="flex flex-col gap-5">
      {/* Nombre del producto */}
      <h2 className="text-2xl font-semibold text-gray-600">
        {productInfo.Descripcion || "Cargando..."}
      </h2>

      {/* Precio del producto */}
      <p className="text-xl font-semibold">
  {productInfo.PrecioOferta > 0 ? (
    <>
      <span style={{ textDecoration: "line-through" }}>
        {productInfo.simbolo}{formatPrice(productInfo.PrecioLista)}
      </span>
      &nbsp;
      <span>{productInfo.simbolo}{formatPrice(productInfo.PrecioOferta)}</span>
    </>
  ) : (
    <span>
      {productInfo.simbolo} {formatPrice(productInfo.PrecioLista)}
    </span>
  )}
</p>


      {/* Código del producto */}
      <p className="text-base text-gray-600">
        {productInfo.codigo || "Cargando..."}
      </p>

      {/* Cantidad y botón */}
      <div className="flex items-center gap-3">
        <label htmlFor="quantity" className="text-lg font-semibold">
          Cantidad:
        </label>
        <input
          id="quantity"
          type="number"
          value={quantity}
          min="1"
          onChange={handleQuantityChange}
          className="w-16 p-2 border border-gray-300 rounded"
        />
        <button
          onClick={() =>
            dispatch(
              addToCart({
                ...productInfo,
                quantity: quantity,
              })
            )
          }
          className="py-2 px-4 bg-primeColor hover:bg-black duration-300 text-white text-sm font-medium rounded"
        >
          Añadir producto
        </button>
      </div>
    </div>
  );
};

export default ProductInfo;
