import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom"; // Para redirigir al usuario
import Breadcrumbs from "../../components/pageProps/Breadcrumbs";

const Profile = () => {
  const [user, setUser] = useState(null); // Estado para almacenar los datos del usuario
  const navigate = useNavigate(); // Inicializamos useNavigate para redireccionar

  // Al cargar la página, verificamos si el usuario está logueado
  useEffect(() => {
    const loggedUserData = JSON.parse(localStorage.getItem("user")); // Obtenemos el objeto completo de localStorage
    if (loggedUserData && loggedUserData.data && loggedUserData.data.user) {
      setUser(loggedUserData.data.user); // Accedemos a la propiedad data.user
      console.log(loggedUserData.data.user); // Mostramos el objeto user en consola
    } else {
      navigate("/signin"); // Si no hay usuario, redirige a la página de inicio de sesión
    }
  }, [navigate]);

  // Función para manejar el cierre de sesión
  const handleLogout = () => {
    localStorage.removeItem("user"); // Eliminamos los datos del usuario de localStorage
    navigate("/signin"); // Redirigimos a la página de inicio
  };
  const handleNavigateHome = () => {
    navigate("/"); // Redirigir a la página de inicio
  };
  return (
    <div className="max-w-container mx-auto px-4">
      <Breadcrumbs title="Profile" />
      <div className="pb-10 text-center">
        {user ? (
          <>
            <h1 className="text-4xl font-bold">Bienvenido, {user.nombre}!</h1> {/* Muestra el nombre */}
            <button
              onClick={handleNavigateHome}
              className="mt-6 w-40 h-10 bg-primeColor text-white text-lg hover:bg-black duration-300 mr-4"
            >
              Inicio
            </button>
            <button
              onClick={handleLogout}
              className="mt-6 w-40 h-10 bg-primeColor text-white text-lg hover:bg-black duration-300"
            >
              Cerrar sesión
            </button>
          </>
        ) : (
          <p>Cargando...</p> // Muestra un mensaje mientras carga la info del usuario
        )}
      </div>
    </div>
  );
};

export default Profile;
