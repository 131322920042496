import React, { useState, useEffect } from "react";
import ReactPaginate from "react-paginate";
import Product from "../../home/Products/Product";
import { paginationItems,paginationItems2,paginationItems3,paginationMeta3,paginationMeta2, paginationMeta, loadOffer,loadProducts,loadNews} from "../../../constants/products";
import { loadUserConfig } from "../../../constants/config";
import { formatPrice} from "../../../constants/funciones";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { addToCart } from "../../../redux/orebiSlice";
import ProductTable from "./ProductTable";



const Items = ({ products, viewType, hoveredItem, setHoveredItem }) => {
  const [quantities, setQuantities] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleQuantityChange = (codigo, value) => {
    setQuantities(prevQuantities => ({
      ...prevQuantities,
      [codigo]: Number(value)
    }));
  };
  
  

  const handleAddToCart = (item, quantityToAdd) => {
    // Asegúrate de que la cantidad se guarda como un número
    setQuantities((prevQuantities) => ({
      ...prevQuantities,
      [item.codigo]: Number(quantityToAdd), // Guarda la cantidad actual
    }));
  
    // Despacha la acción de agregar al carrito con la cantidad
    dispatch(addToCart({
      ...item,
      quantity: Number(quantityToAdd),
    }));
  };
  
  const handleImageClick = (item) => {
    console.log(item.productos_relacionados);
    navigate(`/product/${item.codigo}`, { state: { item } });  // Aquí pasas todo el objeto 'item'
  };
  

 

  return (
    <>
      {viewType === "table" ? (
        <ProductTable
        products={products}
        hoveredItem={hoveredItem}
        setHoveredItem={setHoveredItem}
        handleImageClick={handleImageClick}
        handleQuantityChange={handleQuantityChange}
        handleAddToCart={handleAddToCart}
        quantities={quantities}
      />
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-10 mdl:gap-4 lg:gap-10">
          {products.map((item) => (
            <div key={item.codigo} className="w-full">
              <Product 
              img={item.img} 
              codigo={item.codigo} 
              Descripcion={item.Descripcion} 
              PrecioLista={item.PrecioLista}
              PrecioOferta={item.PrecioOferta}
              />
            </div>
          ))}
        </div>
      )}
    </>
  );
};

const Pagination = () => {
  const [products, setProducts] = useState([]);
  const [itemOffset, setItemOffset] = useState(0);
  const [viewType, setViewType] = useState("table");
  const [hoveredItem, setHoveredItem] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemsPerPage, setItemsPerPage] = useState(50);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedGroup, setSelectedGroup] = useState("0");
  const [selectedBrand, setSelectedBrand] = useState("0");
  const [selectedType, setSelectedType] = useState("0");
  const [config, setConfig] = useState({ grupos: [], marcas: [], atributos: [] });
  const location = useLocation();
  const [loading, setLoading] = useState(false); // Loading state
  const [searchParams] = useSearchParams();
 

  useEffect(() => {
    const fetchProducts = async () => {
      setLoading(true); // Start loading
      try {   
        const path = location.pathname;
        const loadFunction = path === "/News" ? loadNews :
                            path === "/Offer" ? loadOffer : 
                            loadProducts;

        await loadFunction(pageNumber, itemsPerPage, searchTerm, selectedGroup, selectedType, selectedBrand);
        setProducts(path === "/News" ? paginationItems2 :
                     path === "/Offer" ? paginationItems3 : 
                     paginationItems);

      } catch (error) {
        console.error("Error loading products:", error);
      } finally {
        setLoading(false); // End loading
      }
    };
    const fetchConfig = async () => {
      const userConfig = await loadUserConfig(); // Cargar configuración de usuario
      setConfig(userConfig); // Establecer la configuración
    };
    fetchProducts();
    fetchConfig();
  }, [pageNumber, itemsPerPage, searchTerm, selectedGroup, selectedBrand, selectedType]);
// Resetear el número de página cuando cambie la cantidad de productos por página
  useEffect(() => {
  setPageNumber(1);
}, [itemsPerPage]);

  //busqueda input 
  const handleSearchTermChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    if (value.length > 3) {
      const loadFunction = location.pathname === "/News" ? loadNews :
      location.pathname === "/Offer" ? loadOffer : 
      loadProducts;

     loadFunction(pageNumber, itemsPerPage, value, selectedGroup, selectedType, selectedBrand);
    
    }
  };

  //cantidad de paginas
  const pageCount = location.pathname === "/News" 
  ? paginationMeta2.totalPages 
  : location.pathname === "/Offer" 
    ? paginationMeta3.totalPages 
    : paginationMeta.totalPages;

  //cambio de pagina
  const handlePageClick = (event) => {
    const newPage = event.selected + 1;
    setPageNumber(newPage);
  };

  // Manejador para cambios en los selectores
  const handleSelectChange = () => {
    const loadFunction = location.pathname === "/News" ? loadNews :
                        location.pathname === "/Offer" ? loadOffer : 
                        loadProducts;

    loadFunction(pageNumber, itemsPerPage, searchTerm, selectedGroup, selectedType, selectedBrand);
  };

    // Manejar el cambio de vista
    const handleViewChange = (event) => {
      setViewType(event.target.value); // Cambiar la vista a grid o tabla
    };

    
  return (
    <div className="p-5">
      <div className="flex mb-4">
        <input
          type="text"
          placeholder="Buscar productos..."
          value={searchTerm}
          onChange={handleSearchTermChange}
          className="flex-grow border border-gray-300 rounded p-2"
        />
        <select value={itemsPerPage} onChange={(e) => setItemsPerPage(parseInt(e.target.value, 10))} className="ml-2 border border-gray-300 rounded p-2">
          <option value={50}>50</option>
          <option value={75}>75</option>
          <option value={100}>100</option>
          <option value={-1}>todos</option>
        </select>

        <select
          value={viewType}
          onChange={handleViewChange}
          className="ml-2 border border-gray-300 rounded p-2"
        >
          <option value="grid">Grid</option>
          <option value="table">Tabla</option>
        </select>

        </div>
         {/* Selectores de grupo, tipo y marca */}
        
         <div className="flex mb-4">
         <select value={selectedType} onChange={(e) => { setSelectedType(e.target.value); handleSelectChange(); }} className="mr-2 border border-gray-300 rounded p-2 w-full">
          <option value="0">Seleccionar Condicion</option>
          {config.atributos.map((tipo, index) => (
    <option key={index} value={tipo.codigo}> 
      {tipo.descripcion} 
    </option>
  ))}
        </select>
         <select value={selectedGroup} onChange={(e) => { setSelectedGroup(e.target.value); handleSelectChange(); }} className="mr-2 border border-gray-300 rounded p-2 w-full">
          <option value="0">Seleccionar Grupo</option>
          {config.grupos.map((grupo, index) => (
    <option key={index} value={grupo.codigo}> 
      {grupo.descripcion}
    </option>
  ))}
        </select>
       
        <select value={selectedBrand} onChange={(e) => { setSelectedBrand(e.target.value); handleSelectChange(); }} className="mr-2 border border-gray-300 rounded p-2 w-full">
          <option value="0">Seleccionar Marca</option>
          {config.marcas.map((marca, index) => (
    <option key={index} value={marca.clave_id}> 
      {marca.descripcion} 
    </option>
  ))}
        </select>
      
        </div>
      
      {loading ? ( // Conditional rendering for loading state
        <div className="text-center text-xl my-4">Cargando...</div>
      ) : (
        <Items products={products} viewType={viewType} hoveredItem={hoveredItem} setHoveredItem={setHoveredItem} />
      )}
      {/* Agregar paginación si es necesario */}
      {pageCount > 1 && (
      <ReactPaginate
        nextLabel="Siguiente"
        onPageChange={handlePageClick}
        pageRangeDisplayed={3}
        marginPagesDisplayed={1}
        pageCount={pageCount}
        forcePage={pageNumber - 1}  // Forzamos la página seleccionada en el componente
        previousLabel="Anterior"
        pageClassName="page-item mx-1 rounded-full text-gray-700 border border-gray-300 hover:bg-blue-100"
        pageLinkClassName="page-link px-3 py-1"
        previousClassName="page-item mx-1 rounded-full text-gray-700 border border-gray-300 hover:bg-blue-100"
        previousLinkClassName="page-link px-3 py-1"
        nextClassName="page-item mx-1 rounded-full text-gray-700 border border-gray-300 hover:bg-blue-100"
        nextLinkClassName="page-link px-3 py-1"
        breakLabel="..."
        breakClassName="page-item mx-1"
        breakLinkClassName="page-link px-3 py-1"
        containerClassName="pagination flex justify-center items-center mt-8"
        activeClassName="bg-blue-500 text-white rounded-full"
      />
      
    )}
    </div>
    
  );
};

export default Pagination;
